import {Container,Row,Col,Form,Button} from 'react-bootstrap'
import React from "react"
import Layout from "../components/layouts/layout"
import SEO from "../components/seo"
import Pageart from "../components/pageart/pageart"
import Headline from "../components/headline/headline"
import $ from "jquery"


export default class SignUpForm extends React.Component {

    constructor(props) {
       super(props);
       this.state ={
         validated: false,

       };
    }

    componentDidMount(){
    }

    checkToggle = () =>{
      this.state.validated === false ? this.setState({validated: true}) : this.setState({validated:false})
    }

   submitSignUpForm = (firstName, lastName, email, phone, callback) => {
      var payload = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        mobileID: phone
      }
    // fetch('http://localhost:8888/',
    //     {
    //     method : 'POST',
    //     body: payload
    //     }
    //   ).then((response) => console.log(response)).catch(function(err){
    //     console.log(err)
    //   })
      $.ajax({
        url: "/phpSide/",
        data: payload,
        type: "POST",
        crossDomain: true,
        complete: function (resp) {
          console.log('complete')
          callback(null, resp);
        },
        success: function(resp){
          console.log(resp)
          if(resp.status == 200) {
            console.log("form submitted");
            callback(null, resp);

          }
          else if (resp.status == 500) {
            console.log('err')
            console.log(resp)
          }
          else{
            console.log(resp)
          }
        },
        error: function(resp) {
          console.log(resp)
          if(resp.status == 200) {
            console.log("form submitted");
            callback(null, resp);
          }
          else if (resp.status == 500) {
            console.log('err')
            callback(resp)
          }
        }
      });
    }




    handleSubmit = (event) => {
      const form = event.currentTarget;
      form.classList.add("was-validated")
      if (form.checkValidity() === false || this.state.validated === false) {
      if(document.getElementsByClassName("checkbox")[0].checked === false){
        document.getElementsByClassName("checkbox-failed")[0].style.display = "block"
        document.getElementsByClassName("custom-control-label")[0].classList.add("checkbox-failed")
      }
        event.preventDefault();
        event.stopPropagation();

      }
      if (form.checkValidity() === true && this.state.validated === true) {
        if(form.elements["mobileID"].value.length > 9){
          this.submitSignUpForm(form.elements["firstName"].value, form.elements["lastName"].value, form.elements["email"].value, form.elements["mobileID"].value,
          function (err, res) {
          if (err) {
            console.log(err);
          } else {
              console.log(res);
          }
          });
        }
        else {
          this.submitSignUpForm(form.elements["firstName"].value, form.elements["lastName"].value, form.elements["email"].value,
          function (err, res) {
          if (err) {
            console.log(err);
          } else {
              console.log(res);
          }
          });
        }
      } 





    };

    render(){
        return(

          <Layout>
            <div className="signup">

              <SEO title="Sign Up" />

              <Pageart />

                <Container className="lift">
                  <Row>
                    <Col className="content-wrapper">

                      <h1><strong>Sign up</strong><span className="d-lg-none"><br/></span> to learn more</h1>

                      <p className="text-muted">* Indicates required field.</p>

                      <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit.bind(this)} action="/sign-up-thankyou">

                        <Form.Group className="required" controlId="validationCustom01">
                          <Form.Control
                            name="firstName"
                            type="text"
                            placeholder="First name"
                            required
                          />
                        </Form.Group>


                        <Form.Group className="required" controlId="validationCustom02">
                          <Form.Control
                            name="lastName"
                            type="text"
                            placeholder="Last name"
                            required
                          />
                        </Form.Group>

                        <Form.Group className="required" controlId="validationCustom03">
                          <Form.Control
                            name="email"
                            type="email"
                            placeholder="Email address"
                            required
                          />
                        </Form.Group>

                        <Form.Group controlId="validationCustom03">
                          <Form.Control
                            name="mobileID"
                            type="number"
                            placeholder="Phone"
                          />
                        </Form.Group>

                        <span style={{fontSize:"14px", color:"red", display: 'none'}} class="checkbox-failed">Opt-in is required.</span>
                        <Form.Group className="checkbox-group required">

                          <div class="custom-control custom-checkbox mb-3">
                            <input onClick={this.checkToggle} type="checkbox" className="custom-control-input checkbox required" id="customCheck" name="example1" />
                            <label className="custom-control-label" for="customCheck"></label>
                          </div>

                          <div className="checkbox-label">
                            By registering at this site, you certify that you reside in the United States and are at least 18 years of age. Amarin Pharma, Inc. understands that your personal and health information is private. The information you provide will only be used by Amarin Pharma, Inc. and parties acting on its behalf to send you the materials requested and other helpful information and updates, as well as related information and services. We will protect your privacy in accordance with our privacy policy. You understand that unless you “opt-out” by clicking unsubscribe in a promotional email, your consent will <span className="text-nowrap">remain valid</span>.
                          </div>
                          <div className="invalid-feedback">
                            You must agree before submitting.
                          </div>
                        </Form.Group>

                        <Button type="submit">Submit</Button>

                      </Form>

                    </Col>
                  </Row>
                </Container>

            </div>
          </Layout>

        )
    }

  }
